(function(){
     'use strict';

     angular
        .module('app')
        .factory('PapersService', PapersService);

    PapersService.$inject = ['$http'];

    function PapersService($http){
        var obj = { 
        	params : {
	        	filtro:'paper'
	        },
        	getItems: getItems
        };
        return obj;

        function getItems(params,callback)
        {
            var config = {
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                }
            }
            var data = $.param({
                 params:JSON.stringify(params)
            });
            $http.get('/ajax/getpapers', {params:params}, config)
            .then(getContentSuccess)
            .catch(getContentFailed);
            function getContentSuccess(data){ 
                callback(data.data);
            }
            function getContentFailed(data){
                
                return false;
            }
        }
    }
})();