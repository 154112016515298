(function(){
    'use strict';
   
    angular
    .module('app')
    .controller('AppController', AppController)
 
    AppController.$inject = ["PapersService","$http","$timeout","$log", "$document","$sce"];
   
    function AppController(PapersService,$http, $timeout, $log, $document,$sce)
    {          
        var vm = this; 

        vm.paper = PapersService.params;
        console.log("test");

        vm.decir = function(){
            console.log(vm.paper);
        }
        vm.initItems = function(){
            console.log("init");
            PapersService.getItems(vm.paper,function(data){
                vm.paper.items = data.papers;
                vm.paper.anosFinal = data.anosFinal;
                vm.paper.anoActual = data.anosFinal[0];
                console.log(data);
            });
        }

        vm.cambiarAno = function(ano){
            vm.paper.anoActual = ano;
            vm.initItems();
        }

    }
})();