(function(){
    angular
        .module('app')
        .directive('iCheck', iCheck);

        iCheck.$inject = ['$timeout','$window','$document'];
        function iCheck($timeout,$window,$document){
            return {
          require: 'ngModel',
            link: function ($scope, element, $attrs, ngModel) {
           return $timeout(function () {
               var value = $attrs.value;
               var $element = $(element);

               // Instantiate the iCheck control.                            
               /*$element.iCheck({
                   checkboxClass: 'icheckbox_square-green',
                   radioClass: 'iradio_square-green',
                   increaseArea: '20%'
               });*/
            $element.iCheck();

               // If the model changes, update the iCheck control.
               $scope.$watch($attrs.ngModel, function (newValue) {
                   $element.iCheck('update');
               });

               // If the iCheck control changes, update the model.
               $element.on('ifChanged', function (event) {
                
                   if ($element.attr('type') === 'radio' && $attrs.ngModel) {
                       $scope.$apply(function () {
                           ngModel.$setViewValue(value);
                       });
                   }
                  if ($(element).attr('type') === 'checkbox' && $attrs['ngModel']) {
                   $scope.$apply(function() {
                       return ngModel.$setViewValue(event.target.checked);
                   });
                }

               });

           });
       }
          }
      }
})();