var swiper, mobile, navtopPosition, navtopPositionOff;

// Used to compensante Chrome/Safari bug (they don't care about scroll bar for width)
function scrollCompensate() 
{
    var inner = document.createElement('p');
    inner.style.width = "100%";
    inner.style.height = "200px";

    var outer = document.createElement('div');
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild(inner);

    document.body.appendChild(outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    var w2 = inner.offsetWidth;
    if (w1 == w2) w2 = outer.clientWidth;

    document.body.removeChild(outer);

    return (w1 - w2);
}

function resize(){
    // ajuste tamaño videos

    if ($('#header-top').length != 0 && $('.nav-top').length != 0){
        var restar = 0;
        restar += $('#header-top').height();
        var navOffset = $('.nav-top').offset();
        $('.maxHeightHead').css({"min-height":$('#head-page').height()+"px"});
        navtopPositionOff = navOffset.top - restar;
    }


    if (!mobile){
        // Controlar si ha saltado version mobile
        if (Modernizr.touch) {$('#wrapper').width($(window).width());}
        if ($(window).width()+scrollCompensate() < 740) { 
           
            $('#aside-header .scroll').height('');
            $('.box-groups').isotope('destroy');

            swiper = new Swiper('.box-groups', {
                slidesPerView: 'auto',
                paginationClickable: false,
                spaceBetween: 10,
                observer: true
            });
            setTimeout(function() {
                $(document).on("click", '.btn-return', function() {
                    swip = $(this).closest('.swiper-container-horizontal')[0];
                    swip.swiper.slideTo(0);
                });
            }, 300);
            mobile = true;
        } else {
            if ($(window).width()+scrollCompensate() < 992) {
                $('#aside-header .scroll').height($('#aside-header').height()-82);
            } else {
                $('#aside-header .scroll').height($('#aside-header').height()-208);
            }        
        }
    // Controlar si no mobile version
    } else {
        if ($(window).width()+scrollCompensate() >= 740) {
            $('body').removeClass('ohidden');
            $('#aside-header').width('');
            if ($(window).width()+scrollCompensate() < 992) {
                $('#aside-header .scroll').height($('#aside-header').height()-82);
            } else {
                $('#aside-header .scroll').height($('#aside-header').height()-208);
            }
            $('.nav-top .toggle-menu').css('display', '').parent().removeClass('open');
            
            $('.box-groups').each(function(){ this.swiper.destroy(true, true); });
            setTimeout(function() {$('.swiper-wrapper').attr('style','');}, 300);
            $('.box-groups').isotope({
                itemSelector : '.box',
                masonry: {
                    columnWidth: 224,
                    gutterWidth: 20
                }
            });
            
            mobile = false;  
        } else {
            $('#aside-header .scroll').height($(window).height()-64);
        }
    }
}

// header control to fixed position
function headerFix() {
    if ($(window).scrollTop() > navtopPositionOff) {
        $('#head-page').addClass('fixed');
    } else {
        $('#head-page').removeClass('fixed');
    }
}

function goTopBut() {
    if ($(window).scrollTop() > 200 ) {//&& $(window).scrollTop() < prevScroll+10
        $('#goTop').fadeIn(600);
    } else {
        $('#goTop').fadeOut(600);
    }
}

// Animation elements

$(window).on('scroll resize', function () {
    headerFix();
    goTopBut();
});
$(window).trigger('scroll');

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});

$(function(){
    $('input').iCheck();
    if($('#head-page').length>0){
        navtopPosition = parseInt($('#head-page').offset().top);
    }

    /* var swiper = new Swiper('.swiper-container', {
        slidesPerView: 'auto',
        spaceBetween: 24
    });
     var swiper2 = new Swiper('.swiper-container', {
        slidesPerView: 'auto',
        spaceBetween: 24
    });*/

    // modified Isotope methods for gutters in masonry
    $.Isotope.prototype._getMasonryGutterColumns = function() {
        var gutter = this.options.masonry && this.options.masonry.gutterWidth || 0;
        containerWidth = this.element.width();

        this.masonry.columnWidth = this.options.masonry && this.options.masonry.columnWidth ||
        // or use the size of the first item
        this.$filteredAtoms.outerWidth(true) ||
        // if there's no items, use size of container
        containerWidth;

        this.masonry.columnWidth += gutter;

        this.masonry.cols = Math.floor((containerWidth + gutter) / this.masonry.columnWidth);
        this.masonry.cols = Math.max(this.masonry.cols, 1);
    };

    $.Isotope.prototype._masonryReset = function() {
        // layout-specific props
        this.masonry = {};
        // FIXME shouldn't have to call this again
        this._getMasonryGutterColumns();
        var i = this.masonry.cols;
        this.masonry.colYs = [];
        while (i--) {
            this.masonry.colYs.push(0);
        }
    };

    $.Isotope.prototype._masonryResizeChanged = function() {
        var prevSegments = this.masonry.cols;
        // update cols/rows
        this._getMasonryGutterColumns();
        // return if updated cols/rows is not equal to previous
        return (this.masonry.cols !== prevSegments);
    };

   /* if ($(window).width()+scrollCompensate() >= 992) { //version desktop
        $(".banner-boxes").each(function(){
            $(this).children('.banner-caption').height($(this).height()-24);
        });
    } else {
        $(".banner-boxes .banner-caption").height('');
    }*/
    
    $('#aside-header .scroll').jScrollPane({autoReinitialise: true});

    // Controlar si ha saltado version mobile
   
    if ($(window).width()+scrollCompensate() < 740) { 
        mobile = true;
        $('#aside-header .scroll').height('');
        if($('.box-groups').length>0){
            swiper = new Swiper('.box-groups', {
                slidesPerView: 'auto',
                paginationClickable: false,
                spaceBetween: 10,
                observer: true
            });
        }
        setTimeout(function() {
            $(document).on("click", '.btn-return', function() {
                swip = $(this).closest('.swiper-container-horizontal')[0];
                swip.swiper.slideTo(0);
            });
        }, 300);
    // Controlar si no mobile version
    } else {
        mobile = false;
        if ($(window).width()+scrollCompensate() < 992) {
            $('#aside-header .scroll').height($('#aside-header').height()-82);
        } else {
            $('#aside-header .scroll').height($('#aside-header').height()-208);
        }        
        $('.box-groups').isotope({
            itemSelector : '.box',
            masonry: {
                columnWidth: 224,
                gutterWidth: 20
            }
        });
    }

    if ($('.btn-fixed').length != 0){
        $('#goTop').addClass('btnf');
    }

    // animacion al anchor
    $('a').click(function() {
        var href = $(this).attr("href");
        var fLetter = href.slice(0,1);
        if (fLetter == '#' && href.length != 0 && $(this).attr('data-toggle')!="collapse"){
            var hrefTop = $(href).offset().top;
            if (!$('#head-page').hasClass('fixed')){
                hrefTop = hrefTop - $('#head-page').height();
            }
            $('html, body').animate({ 
                scrollTop: (hrefTop - 100 - $('#head-page .nav-top').height())
            }, 500);
            
            return false;
        }
    });

    $(document).on("click", "#btn-menu", function() {
        if ($('#aside-header').hasClass('open')){
    		$('#nav-principal .dropleft-menu').removeClass('open');
    		$('#container').removeClass('submenu-open');
    		setTimeout(function() {                    	
    			$('#aside-header').removeClass('open');
                if ($(window).width()+scrollCompensate() < 740){
                   $('#aside-header').width('');
                   $('body').removeClass('ohidden');
                }
    			$('#container').removeClass('menu-open');
    			$('#btn-menu').children('.icon').removeClass().addClass('icon icon-menu');
                menu=false;
                submenu=false;
    		},400);
        }else{
            $(this).children('.icon').removeClass().addClass('icon icon-close');
            $('#aside-header').addClass('open');
            if ($(window).width()+scrollCompensate() < 740){
                $('#aside-header').width('100%');
                $('body').addClass('ohidden');
            }
            $('#container').addClass('menu-open');
            menu=true;
        };
    }).on('click','.toggle',function(){
        $(this).next('.toggle-menu').slideToggle();
        if ($(this).parent().hasClass('open')){
            $(this).parent().removeClass('open');
            $(this).parent().css('max-height','');
        } else {
            $(this).parent().addClass('open');
            $(this).parent().css('max-height',$(window).height()-64);
        }
    }).on('click','#showSearch',function(){
        $('#busquedaForm').fadeIn();
        $('#busq').focus();
    }).on('click','#closeSearch',function(){
        $('#busquedaForm').fadeOut();
    })

    // Redimension de .box para mostrar/ocultar contenido completo
    $(document).on("click","#goTop",function(){
        var body = $("html, body");
        body.stop().animate({scrollTop:0}, '600', 'swing');
    });
});

$(window).on("load",function(){
    resize();
})

$(window).resize(function(){
    resize();
});


