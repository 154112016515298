function validarCP(valor) {
	var RegExPatternX = new RegExp("[0123456789]");
	if (valor.match(RegExPatternX)) {
		return true;
	}
	else {
		return false;
	}
}

function getCalendar(baseUrl,fecha,apart_id){
	$('#loading').show();
	$.post(baseUrl+"/admin/bloqueos/getcalendar",{fecha:fecha,apart_id:apart_id},function(data){
		$('#calendario').html(data);
		$('#loading').hide();
	});
}

function comprobar(){
	var res = true;
	$('.obli').removeClass("rojo");
	$('.obli').each(function(){
		if($(this).val()=="") {
			$(this).addClass("rojo");
			if(res==true){
				res=false;
				alert("Rellene todos los campos obligatorios.");
			}
		}
	});
	return res;
}

function esFecha(str){
	var fecha = str.split("/");
	var elDia = fecha[0];
	var elMes = fecha[1];
	var elAnio = fecha[2];
	var ff = new Date(elAnio, elMes, elDia);
	if (ff.getDate() != elDia )
		return false;
	if (ff.getMonth() != elMes)
		return false;
	if (ff.getFullYear() != elAnio)
		return false;
	return true;
}

function validateCaptcha(url)
{
    var html = $.ajax({
    type: "POST",
    url: url,
    data: "recaptcha_challenge_field="+Recaptcha.get_challenge()+"&recaptcha_response_field="+ Recaptcha.get_response(),
    async: false
    }).responseText;
    if(html == "success")
    {
        $("#captchaStatus").html(" ");
        // Uncomment the following line in your application
        return true;
    }
    else
    {
        alert("El código antispam es incorrecto, por favor vuelva a intentarlo.");
        Recaptcha.reload();
        return false;
    }
}

function validarEmail(valor) {
	var re=/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,3})$/;
	if(!re.exec(valor)) {
		return false;
	}else{
		return true;
	}
}

function isValidNif(abc){
	dni=abc.substring(0,abc.length-1);
	let=abc.charAt(abc.length-1);
	if (!isNaN(let)) {
		//alert('Falta la letra');
		return false;
	}else{
		cadena = "TRWAGMYFPDXBNJZSQVHLCKET";
		posicion = dni % 23;
		letra = cadena.substring(posicion,posicion+1);
		if (letra!=let.toUpperCase()){
			//alert("Nif no válido");
			return false;
		}
	}
	//alert("Nif válido")
	return true;
}

function validaTelefono(telefono){
	var RegExPatternX = new RegExp("[0123456789 -]");
	if (telefono.match(RegExPatternX) && telefono.length>8 && telefono.length<20) {
		return true;
	}
	else {
		return false;
	}
}

function get_param( name ){
	name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");  
	var regexS = "[\\?&]"+name+"=([^&#]*)";  
	var regex = new RegExp( regexS );  
	var results = regex.exec( window.location.href ); 
	if( results == null )    return "";  
	else    return results[1];
}